<div class="row row-constrain">
  <div class="col-12 col-md-4 pr-0">
    <mat-form-field class="w-50">
      <input matInput [matDatepicker]="dateFrom" [max]="selectedDateTo" i18n-placeholder placeholder="Date From" class="md-no-underline" [formControl]="dateFromControl" [(ngModel)]="selectedDateFrom">
      <button mat-button *ngIf="selectedDateFrom" matSuffix mat-icon-button (click)="selectedDateFrom=''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix color="primary" [for]="dateFrom"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #dateFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="w-50">
      <input matInput [matDatepicker]="dateTo" [min]="selectedDateFrom" i18n-placeholder placeholder="Date To" [formControl]="dateToControl" [(ngModel)]="selectedDateTo">
      <button mat-button *ngIf="selectedDateTo" matSuffix mat-icon-button (click)="selectedDateTo=''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle color="primary" matSuffix [for]="dateTo"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #dateTo></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="field-container col-12 col-md-2 pr-0">
    <mat-form-field class="mw-100">
      <mat-select i18n-placeholder placeholder="Exchange" [formControl]="market_control" [(ngModel)]="selected_market">
        <mat-option *ngFor="let exchange of exchange_options"  [value]="exchange.id">
          {{exchange.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="field-container col-12 col-md-2 pr-0">
    <mat-form-field class="w-100">
      <mat-select i18n-placeholder placeholder="Action" [formControl]="actionControl" [(ngModel)]="selectedAction">
        <mat-option *ngFor="let status of statusOptions"  [value]="status.id" >
          {{status.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="field-container col-12 col-md-2 align-self-center">
    <div class="row">
    <button mat-raised-button color="primary" class="col p-0 search-btn" (click)="loadTableData()" i18n>SEARCH</button>
    <ng-container *ngIf="this.DA_data.data.length > 0">
      <a mat-raised-button class="file-download col-auto px-0 ml-0" (click)="downloadCSV()" *ngIf="downloadStarted && !csvRecords; else generatingData">
        <mat-icon class="download-icon w-100" i18n-matTooltip matTooltip="Download as report">
          file_download
        </mat-icon>
      </a>
      <ng-template #generatingData>
        <mat-progress-spinner class="d-inline-block" diameter="35" strokeWidth="8" mode="indeterminate"></mat-progress-spinner>
      </ng-template>
    </ng-container>
    </div>
  </div>
</div>

<div class="table-content">
  <mat-table matSort [dataSource]="DA_data" (matSortChange)="loadTableData()" class="display-grid vertical-scroll">
    <ng-container matColumnDef="market">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Market </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{getMarketPair(user.market)}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_at_date">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Date </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{getDateNum(user.created_at_date)}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="order_type">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Type </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{displayTypeNiceName(user.order_type)}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="price_change">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Change </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{user.price_change| decimalNumber: user.exchange_decimals}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="order_amount">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Order Amount </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{user.order_amount| decimalNumber: user.coin_decimals}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cancelled_date">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Other Settings </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table">
        <mat-icon color="primary" data-container="body" placement="bottom" triggers="hover click" i18n-matTooltip matTooltip="{{buildDAtooltip(user.price_change_direction, user.match_opposite_orders, user.use_orderbook_price)}}">info</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="last_price">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Last Price </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{user.last_price| decimalNumber: user.exchange_decimals}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="limit_price">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Maximum Price </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{user.limit_price| decimalNumber: user.exchange_decimals}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="amount_traded">
      <mat-header-cell *matHeaderCellDef class="text-align-table"> Current Amount </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{user.amount_traded| decimalNumber: user.coin_decimals}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="limit_total_coin_amount">
      <mat-header-cell  class="text-align-table" *matHeaderCellDef> Total Amount </mat-header-cell>
      <mat-cell *matCellDef="let user" class="text-align-table"> {{user.limit_total_coin_amount| decimalNumber: user.exchange_decimals}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cancellation_reason" stickyEnd>
      <mat-header-cell *matHeaderCellDef class="text-align-table"></mat-header-cell>
      <mat-cell *matCellDef="let element" align="right" class="text-align-table">
        <mat-icon color="primary" (click)="cancelOrder(element, 0)" data-container="body" i18n-matTooltip matTooltip="Cancel order">close</mat-icon>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="DA_definition"></mat-header-row>
    <mat-row *matRowDef="let row; columns: DA_definition;"></mat-row>
  </mat-table>

  <div class="w-100 text-center" *ngIf="this.DA_data.data.length === 0" i18n>No records found</div>
</div>
