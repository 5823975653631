import {Component, OnInit, ViewChild, OnDestroy, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {HistoryService} from '../history.service';
import {MarketsService} from '../../markets/markets.service';
import {DialogData, MarketConfirmationComponent} from '../../markets/market-confirmation/market-confirmation.component';
import {I18nTranslationService} from '../../core/services/i18n-translation.service';
import * as moment from 'moment';
import {StoreService, SubscriptionLike} from 'src/app/core/services/store.service';
import { generateCSV } from 'src/app/core/services/util.service';
import { UtilHelper } from 'src/app/core/helpers/util-helper';


@Component({
  selector: 'app-da-orders-table',
  templateUrl: './da-orders-table.component.html',
  styleUrls: ['./da-orders-table.component.scss', '../history-dash/history.component.scss']
})
export class DaOrdersTableComponent implements OnInit, OnDestroy {

  public valueChange: Function = UtilHelper.bigValueToFixed;

  DA_definition: any = [
    'market',
    'created_at_date',
    'order_type',
    'price_change',
    'order_amount',
    'cancelled_date',
    'last_price',
    'limit_price',
    'amount_traded',
    'limit_total_coin_amount',
    'cancellation_reason'
  ];

  private subs: SubscriptionLike[] = [];

  coinOptions: any[] = [];
  exchange_options: any[] = [];
  statusOptions: any[] = [
    {id: '-1', name: 'ALL'},
    {id: '0', name: 'ACCUMULATION'},
    {id: '1', name: 'DISTRIBUTION'}];

  DA_data: MatTableDataSource<any> = new MatTableDataSource([]);
  data: any = {
    market_id: Number(this.marketService.activeMarket.id),
    allorders: true
  };

  csvRecords: boolean = false;
  selectedCoin: any = '-1';
  selected_market: any = '-1';
  selectedDateFrom: any = null;
  selectedDateTo: any = null;
  selectedAction: any = '-1';

  coinControl: FormControl = new FormControl(['']);
  market_control: FormControl = new FormControl(['']);
  dateFromControl: FormControl = new FormControl(['']);
  dateToControl: FormControl = new FormControl(['']);
  actionControl: FormControl = new FormControl(['']);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  showTable: boolean = false;
  private orderType: string;
  downloadStarted: boolean = true;

  constructor(
    private historyService: HistoryService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    private marketService: MarketsService,
    private store: StoreService
  ) { }

  ngOnInit() {
    this.getPairs();
    this.historyService.getDAOrders(this.data).subscribe((response: any) => {
      if (response.data[0] != null) {
        this.showTable = true;
      }
      const newData = [];
      response.data.forEach(daData => {
        const daDataUpdated = daData;
        const marketData = this.marketService.getMarketPair(daData.market);
        if (marketData) {
          daDataUpdated['coin_decimals'] = marketData.coinDecimals;
          daDataUpdated['exchange_decimals'] = marketData.exchangeDecimals;
        }
        newData.push(daDataUpdated);
      });
      this.DA_data.data = newData;
    });
    this.subs.push(this.store.subscribe('daOrders').subscribe((response: any) => {
      if (!!response.refresh) {
        if (response.title === 'DA Order Cancelled' || response.title === 'DA Order Added') {
          this.reset();
        }
      }
    }));
    this.subs.push(this.store.subscribe('daOrderUpdate').subscribe((response) => {
      if (!!response.refresh) {
        this.reset();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getDateNum(dateString: any) {
    return moment(Number(dateString) * 1000).format('YYYY/MM/DD hh:mm:ss');
  }

  returnDirectionString(direction: any, match: any) {
    if ( this.orderType === 'Accumulation') {
      if (direction === '0' && match === '1') {
        return this.i18n('Below lowest ask');
      } else if (direction === '1' && match === '1') {
        return this.i18n('Above lowest ask');
      } else if (direction === '0' && match === '0') {
        return this.i18n('Below highest bid');
      } else if (direction === '1' && match === '0') {
        return this.i18n('Above highest bid');
      }
    } else {
      if (direction === '0' && match === '1') {
        return this.i18n('Below highest bid');
      } else if (direction === '1' && match === '1') {
        return this.i18n('Above highest bid');
      } else if (direction === '0' && match === '0') {
        return this.i18n('Below lowest ask');
      } else if (direction === '1' && match === '0') {
        return this.i18n('Above lowest ask');
      }
    }

  }

  returnSellOrderString(sell: any) {
    if ( this.orderType === 'Accumulation') {
      if (Number(sell) === 1) {
        return this.i18n('Use sell order price');
      } else {
        return '';
      }
    } else {
      if (Number(sell) === 1) {
        return this.i18n('Use buy order price');
      } else {
        return '';
      }
    }

  }

  returnMatchOrderString(sell: any) {
    if (this.orderType === 'Accumulation') {
      if (Number(sell) === 1) {
        return this.i18n('Buy from sell orders');
      } else {
        return '';
      }
    } else {
      if (Number(sell) === 1) {
        return this.i18n('Sell to buy orders');
      } else {
        return '';
      }
    }
  }

  buildDAtooltip(priceDirection: any, matchOrders: any, useOrderBook: any) {
    let returnString: string;
    const directionString = this.returnDirectionString(priceDirection, useOrderBook);
    const matchOrderString = this.returnMatchOrderString(matchOrders);
    const sellOrderString = this.returnSellOrderString(useOrderBook);

    const seperator = (this.csvRecords ? ' ' : '\n');
    returnString = ((directionString !== '') ? ' - ' +  directionString + seperator : '')
      + ((matchOrderString !== '') ? ' - ' + matchOrderString + seperator : '')
      + ((sellOrderString !== '') ?  ' - ' + sellOrderString : '');

    return returnString;
  }

  displayTypeNiceName(e: any) {
    if (e === '0') {
      this.orderType = 'Accumulation';
      return this.i18n('Accumulation');
    } else {
      this.orderType = 'Distribution';
      return this.i18n('Distribution');
    }
  }

  getMarketPair(id: any) {
    const pairFound = this.marketService.getMarketPair(id);
    if (pairFound) {
      return pairFound.marketPair;
    }
  }

  cancelOrder(element: any, reason: any) {
    this.marketService.setActiveMarket(this.marketService.getMarketByID(element.market));
    const activeMarketData = this.marketService.activeMarket;
    const aboveBelow = this.returnDirectionString(element.price_change_direction, element.match_opposite_orders);
    let template =  '';

    // templates
    const temp1 = `Are you sure you would like to cancel the following Order?<br>
    <br><b>Price Change : </b> {{priceChange}} {{exchangeCode}}
    <br><b>{{aboveBelow}} : </b>{{priceChangeDirection}}`;

    const sellTemp = `
    <br><b>Use sell orderbook prices  </b>: {{useOrderbookPrice}}
    <br><b>Buy from sell orders : </b> {{matchOppositeOrders}}
    <br><b>Order placement amount : </b> {{orderAmount}} {{coinCode}}
    <br><b>Maximum buy price : </b> {{limitTotalCoinAmount}} {{exchangeCode}}
    <br><b>Total amount to buy : </b> {{orderAmount}} {{coinCode}}`;

    const buyTemp = `
    <br><b>Use buy orderbook prices  </b>: {{useOrderbookPrice}}
    <br><b>Sell to buy orders : </b> {{matchOppositeOrders}}
    <br><b>Order placement amount : </b> {{orderAmount}} {{coinCode}}
    <br><b>Minimum sell price : </b> {{limitTotalCoinAmount}} {{exchangeCode}}
    <br><b>Total amount to sell : </b> {{orderAmount}} {{coinCode}}`;

    const temp2 = `
    <br><b>Seconds between order placement : </b> {{seconds}}
    <br><br> Please note that cancelling this order will cancel
             the entire process to which it relates and the
             process will therefore not be fulfilled based
             on the settings specified
    <br><br>`;

    // data for the template
    const priceData = {
      aboveBelow: aboveBelow, priceChange: Number(element.price_change).toFixed(activeMarketData.exchangeDecimals),
      priceChangeDirection: (element.price_change_direction === '0' ? 'Below' : 'Above'),
      useOrderbookPrice: (element.use_orderbook_price === '0' ? 'No' : 'Yes'),
      matchOppositeOrders: (element.match_opposite_orders === '0' ? 'No' : 'Yes'),
      orderAmount: Number(element.order_amount).toFixed(activeMarketData.coinDecimals),
      limitTotalCoinAmount: Number(element.limit_total_coin_amount).toFixed(activeMarketData.exchangeDecimals),
      seconds: element.limit_delay,
      coinCode: activeMarketData.coinCode,
      exchangeCode: activeMarketData.exchangeCode
    };

    if (Number(element.order_type) === 0) {
      // sell template
      template = temp1 + sellTemp + temp2;
    } else {
      // buy template
      template =  temp1 + buyTemp + temp2;
    }

    const dialogData: DialogData = {
      title: this.i18n('Cancel Order'),
      result: 'string',
      reason: this.i18n( template, priceData),
      okButton: true,
      isTranslated: true
    };
    const dialogRef = this.dialog.open(MarketConfirmationComponent, {
      width: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.result === 'accept') {
        const cancelData: any = {
          market_id: Number(element.market),
          cancellation_reason: Number(0)
        };

        this.historyService.cancelDAOrder(cancelData).subscribe((response) => {
          this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), {duration: 2000});
          this.reset();
        });
      }
    });
  }

  reset() {
    this.getPairs();
      this.historyService.getDAOrders(this.data).subscribe((response: any) => {
        if (response.data[0] != null) {
          this.showTable = true;
        }
        const newData = [];
        response.data.forEach(daData => {
          const daDataUpdated = daData;
          const marketData = this.marketService.getMarketPair(daData.market);
          if (marketData) {
            daDataUpdated['coin_decimals'] = marketData.coinDecimals;
            daDataUpdated['exchange_decimals'] = marketData.exchangeDecimals;
          }
          newData.push(daDataUpdated);
        });
        this.DA_data.data = newData;
      });
  }

  splitMarket(data: any) {
    this.exchange_options = [{id: '-1', name: 'ALL'}];
    for (let i = 0; i < data.length; i++) {
      if (data[i].exchangeId && !this.checkExchangeArray(data[i].exchangeId)) {
        this.exchange_options.push({id: data[i].exchangeId, name: data[i].exchangeCode});
      }
    }
    this.exchange_options.sort((a: any, b: any) => a.name > b.name ? 1 : -1);
  }

  getunixtime(unixdate: any) {
    return moment.unix(unixdate).format('MM/DD/YYYY');
  }

  checkExchangeArray(id: any) {
    for (let i = 0 ; i < this.exchange_options.length; i++) {
      if (this.exchange_options[i].id === id) {
        return true;
      }
    }
    return false;
  }

  splitExchange(data: any) {
    this.exchange_options = [{id: '-1', name: 'ALL'}];
    for (let i = 0; i < data.length; i++) {
      this.exchange_options.push({id: data[i].id, name: data[i].code});
    }
  }

  getPairs() {
    this.historyService.getMarketData().subscribe((response: any) => {
      if (response.response === 'success') {
        this.splitMarket(response.data);
      }
    });
  }

  downloadCSV() {
    this.csvRecords = true;
    this.downloadStarted = false;
    this.loadTableData();
  }

  loadTableData() {
    this.getPairs();
    this.historyService.getDAOrders(this.data).subscribe((response: any) => {
      const newData = [];
      response.data.forEach(daData => {
        const daDataUpdated = daData;
        const marketData = this.marketService.getMarketPair(daData.market);
        daDataUpdated['coin_decimals'] = marketData.coinDecimals;
        daDataUpdated['exchange_decimals'] = marketData.exchangeDecimals;
        newData.push(daDataUpdated);
      });
      this.DA_data.data = newData;
      let selectedDateFrom1 = null;
      let selectedDateTo1 = null;

      if (this.selectedDateFrom != null) {
        selectedDateFrom1 = moment(new Date(this.selectedDateFrom)).format('X');
      }

      if (this.selectedDateTo != null) {
        selectedDateTo1 = moment(new Date(this.selectedDateTo)).format('X');
      }

      const type = this.selectedAction;
      const market = this.selected_market;

      let filterData: any = this.DA_data.data;
      filterData = this.filterDate(selectedDateFrom1, selectedDateTo1, filterData);
      filterData = this.filterType(type, filterData);
      filterData = this.filterMarket(market, filterData);
      this.DA_data.data = filterData;
      this.showTable = true;

      if (this.csvRecords) {
        if (filterData.length > 0) {
          const csvHeaders = [
            'Market', 'Date', 'Type', 'Change', 'Order Amount',
            'Other Settings', 'Last Price', 'Maximum Price',
            'Current Amount', 'Total Amount'
          ];
          const csvData = [];

          filterData.forEach(daOrder => {
            const coinDecimal = +daOrder.coin_decimals;
            const exchangeDecimal = +daOrder.exchange_decimals;
            const csvRow = [
              this.getMarketPair(daOrder.market),
              this.getDateNum(daOrder.created_at_date),
              this.displayTypeNiceName(daOrder.order_type),
              this.valueChange(daOrder.price_change, exchangeDecimal),
              this.valueChange(daOrder.order_amount, coinDecimal),
              this.buildDAtooltip(daOrder.price_change_direction,
                daOrder.match_opposite_orders, daOrder.use_orderbook_price),
              this.valueChange(daOrder.last_price, exchangeDecimal),
              this.valueChange(daOrder.limit_price, exchangeDecimal),
              this.valueChange(daOrder.amount_traded, coinDecimal),
              this.valueChange(daOrder.limit_total_coin_amount, exchangeDecimal)
            ];

            csvData.push(csvRow);
          });

          if (!generateCSV('active-DA-Orders', csvData, csvHeaders)) {
            this.snackbar.open(
              this.translateService.translateResponse('GENERAL_SOMETHINGWENTWRONGPROMPT'),
              this.i18n('Close'), {duration: 2000});
          }
        }
        this.downloadStarted = true;
        this.csvRecords = false;
      }
    });
  }

  filterDate(from: any, to: any, dataset: any) {
    const originalData = dataset;

    const filterData = originalData.filter(item => {
      const createdAtDateAsNumber = Number(item.created_at_date);
      const toMax = to === null ? Number.MAX_VALUE : to;
      const fromMin = from === null ? Number.MIN_VALUE : from;
      return createdAtDateAsNumber <= toMax || createdAtDateAsNumber >= fromMin;
    });

    return filterData;
  }

  filterType(actiontype: any, dataset: any) {
    const originalData = dataset;

    if (Number(actiontype) === -1) {
      return originalData;
    }

    const filterData = originalData.filter(item => {
      const actionType = Number(item.order_type);
      return actionType === Number(actiontype);
    });

    return filterData;
  }

  filterMarket(markettype: any, dataset: any) {
    const originalData = dataset;

    if (Number(markettype) === -1) {
      return originalData;
    }

    const filterData = originalData.filter(item => {
      const marketType = Number(item.market_id);
      return Number(markettype) === marketType;
    });

    return filterData;
  }
}
